import DialogContent from '@material-ui/core/DialogContent';
import React, { FC } from 'react';
import useStyle from './style';
import clsx from 'clsx';

const CGVContent: FC = () => {
  const classes = useStyle();

  return (
    <div>
      <h1 className={classes.title}>Conditions Générales</h1>
      <div className={classes.textContainer}>
        <div>
          {/******  Section Header ******/}
          <section>
            <p>
              KOAH est une société par actions simplifiée au capital de 292 600 euros, immatriculée
              au registre du commerce et des sociétés de Paris sous le numéro d’identification 797
              978 996, ayant son siège social situé 10, rue de Penthièvre à Paris (75008), et ayant
              pour numéro de TVA intracommunautaire FR86797978996 (le « <b>Domiciliataire </b>»).
            </p>
            <p>
              Le Domiciliataire propose des services de domiciliation aux entreprises. Plusieurs
              offres sont ainsi proposées aux domiciliés (le « <b>Domicilié</b> ») dont le détail
              figure en <u>Annexe A</u> (la ou les « <b>Offre(s)</b> »).
            </p>
            <p>
              Les présentes Conditions Générales s’appliquent, sans restriction ni réserve et à
              l’exclusion de toutes autres conditions, à l’ensemble des souscriptions aux Offres.
            </p>
            <p>
              Elles précisent notamment les conditions de souscription, de paiement et les services
              associés aux Offres.
            </p>
          </section>

          {/****** Division Article préliminaire 0 ******/}
          <div className={classes.textPadding}>
            <p className={classes.articleTitle}>
              Article préliminaire : Documents contractuels - Conditions d’éligibilité -
              Déclarations et garanties
            </p>

            {/****** Section Article préliminaire 0.1 ******/}
            <section>
              <p className={classes.articleTitle}>0.1. Documents contractuels</p>
              <p>Les documents contractuels sont dans l'ordre de priorité décroissant :</p>
              <div>
                <ul className={classes.ulsStyle}>
                  <li>
                    les conditions particulières relatives à l’Offre souscrite comportant notamment
                    la désignation du Domicilié et l’Offre souscrite (les «{' '}
                    <b>Conditions Particulières</b> ») ;
                  </li>
                  <li>les présentes Conditions Générales.</li>
                </ul>
              </div>
              <p>
                L’ensemble des documents précités forment ensemble le contrat unissant les parties
                (le « <b>Contrat</b> »).
              </p>
              <p>
                En cas de contradiction entre une ou plusieurs stipulations figurant dans l'un des
                documents précités, le document de niveau supérieur prévaudra.
              </p>
              <p>
                Les parties aux présentes, le Domiciliataire et le Domicilié, sont désignés pour les
                besoins des présentes par la ou les « Partie(s) ».
              </p>
            </section>

            {/****** Section Article préliminaire 0.2 ******/}
            <section className={classes.textPadding}>
              <p className={classes.articleTitle}>0.2. Conditions d’éligibilité</p>
              <p>
                La domiciliation est soumise à certaines conditions, consultables en <u>Annexe B</u>
                .
              </p>
            </section>

            {/****** Section Article préliminaire 0.3 ******/}
            <section className={classes.textPadding}>
              <p className={classes.articleTitle}>0.3. Déclarations et garanties</p>

              <div>
                <p>Le Domicilié déclare et garantit :</p>
                <ul className={classes.ulsStyle}>
                  <li>avoir pris l’entière connaissance du Contrat et annexes ;</li>
                  <li>
                    avoir reçu toute l’information nécessaire pour prendre une décision éclairée ;
                  </li>
                  <li>avoir la pleine capacité à l’effet de conclure et d’exécuter le Contrat ;</li>
                  <li>
                    et que la conclusion du Contrat ne contrevient à aucune disposition législative,
                    réglementaire, statutaire ou stipulation contractuelle qui lui est applicable.
                  </li>
                </ul>
              </div>
            </section>
          </div>

          {/****** Division Article 1 : Objet du Contrat ******/}
          <div className={classes.textPadding}>
            <p className={classes.articleTitle}>Article 1 : Objet du Contrat</p>
            <p>
              Le présent Contrat a pour objet la domiciliation du siège social du Domicilié,
              conformément aux dispositions des articles R. 123-167 à R. 123-171 du code de
              commerce, à l’adresse stipulée dans les Conditions Particulières.
            </p>
            <p>
              Par exception et en cas de stipulation des Conditions Particulières, le Domiciliataire
              n’assure pas la domiciliation du siège social du Domicilié mais uniquement les
              services visées dans les Conditions Particulières. Par suite, toutes stipulations de
              l’article 2.1 relatives à la domiciliation du siège social n’auront pas vocation à
              s’appliquer.
            </p>
          </div>

          {/****** Division Article 2 : Obligations des Parties ******/}
          <div className={classes.textPadding}>
            <p className={classes.articleTitle}>Article 2 : Obligations des Parties</p>

            {/****** Section Article 2, 2.1 Obligation du domiciliataire ******/}
            <section>
              <p className={classes.articleTitle}>2.1. Obligations du domiciliataire</p>

              <p>
                Conformément aux dispositions légales applicables, le Domiciliataire s’engage,
                pendant toute la durée du Contrat, à :
              </p>
              <div>
                <ul className={classes.ulsStyle}>
                  <li>
                    être immatriculé au registre du commerce et des sociétés ou au répertoire des
                    métiers, durant l’occupation des locaux ;
                  </li>
                  <li>
                    mettre à la disposition du Domicilié des locaux dotés d’une pièce propre à
                    assurer la confidentialité nécessaire et à permettre une réunion régulière des
                    organes chargés de la direction, de l’administration ou de la surveillance de
                    l’entreprise ainsi que la tenue, la conservation et la consultation des livres,
                    registres et documents prescrits par la loi. Les Parties conviendront d’un
                    commun accord des conditions, notamment financières, de ladite mise à
                    disposition;
                  </li>
                  <li>
                    détenir, pour le Domicilié, un dossier contenant les pièces justificatives
                    relatives au domicile de son représentant légal et à ses coordonnées
                    téléphoniques ainsi qu’à chacun de ses lieux d’activité et du lieu de détention
                    des documents comptables lorsqu’ils ne sont pas conservés chez elle;
                  </li>
                  <li>
                    informer le greffier du tribunal, à l’expiration du Contrat ou en cas de
                    résiliation anticipée de celui-ci, de la cessation de la domiciliation de
                    l’entreprise dans ses locaux. Lorsque le Domicilié n’a pas pris connaissance de
                    son courrier depuis trois mois, le Domiciliataire en informe également le
                    greffier;
                  </li>
                  <li>
                    communiquer aux huissiers de justice, munis d’un titre exécutoire, les
                    renseignements propres à joindre le Domicilié ; fournir, chaque trimestre, au
                    centre des impôts et aux organismes de recouvrement des cotisations et
                    contributions de sécurité sociale compétents, une liste des personnes qui se
                    sont domiciliées dans ses locaux au cours de cette période ou qui ont mis fin à
                    leur domiciliation ainsi que chaque année, avant le quinze janvier, une liste
                    des personnes domiciliées au 1er janvier.
                  </li>
                </ul>
              </div>
              <p>
                Le Domicilié est informé que la tenue administrative de son compte client engendre
                des frais administratifs annuels de vingt-neuf (29) euros hors taxes prélevés
                automatiquement par le Domiciliataire, pendant toute la durée du Contrat, au plus
                tard le 31 janvier de chaque année. Ce montant est susceptible d'être réévalué, à la
                hausse ou à la baisse, chaque année.
              </p>
              <p>
                Pendant toute la durée du Contrat et sous réserve de la parfaite exécution des
                obligations du Domicilié, le Domiciliataire s’engage à fournir, en sus de la
                domiciliation visée à l’article 1 :
              </p>
              <div>
                <ul className={classes.ulsStyle}>
                  <li>
                    <p>
                      réception et mise à disposition du courrier destiné au Domicilié : Le
                      Domiciliataire s’engage à réceptionner le courrier postal adressé au Domicilié
                      à l’adresse visée à l’article 1, les jours ouvrés uniquement et pendant les
                      seuls horaires d’ouverture ayant été communiqués au Domicilié préalablement à
                      la conclusion des présentes.
                    </p>
                    <p>
                      Le Domicilié est informé par le Domiciliataire de toute réception de courrier
                      via son espace personnel accessible à l’adresse suivante :{' '}
                      <a href="https://app.digidom.pro" target="_blank">
                        {' '}
                        https://app.digidom.pro
                      </a>{' '}
                      (l’« <b>Espace Personnel</b> »). Par suite, il est recommandé au Domicilié de
                      consulter de manière régulière son Espace Personnel.
                    </p>
                    <p>
                      Le Domicilié est averti des risques de vols, de pertes et/ou de détérioration
                      liés, entre autres, à l’envoi et la réception de courriers et de colis via les
                      services postaux. Par suite, le Domiciliataire ne saurait être responsable en
                      cas de non-réception ou de réception de courriers ou colis endommagés.
                    </p>
                    <p>
                      Les colis et plis spéciaux (DHL, CHRONOPOST, UPS…) ne seront acceptés qu’après
                      remise d’un pouvoir de réception exprès au Domiciliataire. A défaut, les colis
                      et plis spéciaux seront refusés systématiquement par le Domiciliataire. En
                      tout état de cause et eu égard aux difficultés logistiques, les Parties
                      conviennent que le Domiciliataire ne saurait être tenu d’accepter de recevoir
                      tout colis ou plis spécial. L’acceptation desdits colis ou plis par le
                      Domiciliataire est purement discrétionnaire.
                    </p>
                    <p>
                      Les courriers recommandés ne seront acceptés uniquement lorsque le Domicilié a
                      son siège social au sein des locaux du Domiciliataire conformément à l’article
                      1 al. 1 et qu’après remise d’une procuration postale au Domiciliataire afin de
                      pouvoir réceptionner en son nom les envois recommandés. Ladite procuration est
                      consentie pour une durée qui ne saurait excéder la durée du Contrat et tout au
                      plus cinq (5) ans à compter de la conclusion du Contrat. Celle-ci a une durée
                      de 5 ans et Ladite procuration sera renouvelable suite à son expiration pour
                      un montant de cinquante (50) euros hors taxes.
                    </p>
                    <p>
                      Le Domicilié s’engage à aviser le Domiciliataire en le mentionnant sur son
                      Espace Personnel, de l’identité des personnes habilitées à récolter et
                      réceptionner les courriers et colis en fournissant les justificatifs requis
                      (pièces d’identité, procuration). Pour des raisons de confidentialité, aucun
                      des employés du Domiciliataire ne pourra être contraint d’aviser, par
                      téléphone, au Domicilié, l’éventuelle réception de courrier ou de colis.
                    </p>
                    <p>
                      En tout état de cause, le Domiciliataire ne saurait être tenu pour responsable
                      en cas de communication ou transmission du courrier ou de colis à une personne
                      à laquelle il aurait remis de bonne foi lesdits courriers et colis en
                      l’absence des documents ci-avant requis.
                    </p>
                    <p>Le Domicilié dispose d’un délai de :</p>
                    <ul className={classes.ulsStyle}>
                      <li>
                        trois jours ouvrés pour récupérer tout colis reçu, à défaut, ledit colis
                        fera l’objet d’une facturation s’élevant à quinze (15) euros hors taxes par
                        jour de stockage. Les Parties pourront éventuellement convenir d’une
                        réexpédition du colis qui fera l’objet d’une facturation par le
                        Domiciliataire (frais de gestion et de réexpédition) ;
                      </li>
                      <li>
                        trois mois pour récupérer tout courrier reçu à date de réception, à défaut
                        de quoi le Domicilié se verra automatiquement engagé, pour la durée restante
                        de son engagement (3 mois), sur un forfait comprenant la réexpédition du
                        courrier
                      </li>
                    </ul>
                  </li>
                  <li>
                    <p>
                      scan de l’enveloppe : Le Domiciliataire s’engage à scanner chacune des
                      enveloppes, ne dépassant pas 0,4 mm d’épaisseur, reçues par le Domicilié. Sauf
                      stipulations expresses des Conditions Particulières, le Domiciliataire ne
                      saurait être tenu d’ouvrir et/ou de scanner le contenu des enveloppes.
                    </p>
                  </li>
                  <li>
                    <p>en cas de stipulations expresses des Conditions Particulières :</p>
                    <ul className={classes.ulsStyle}>
                      <li>
                        scan du courrier destiné au Domicilié : Le Domiciliataire s’engage à ouvrir,
                        scanner et charger le courrier numérisé sur l’Espace Personnel du Domicilié.
                        Cette prestation est limitée à un maximum de 90 courriers par mois et/ou 120
                        pages numérisées au format A4, recto/verso. Au-delà, chaque page
                        (recto/verso) numérisée, automatiquement et sans information préalable du
                        Domicilié, sera facturée à hauteur de un (1) euro hors taxes par le
                        Domiciliataire.
                      </li>
                      <li>
                        <p>
                          réexpédition du courrier : Le Domiciliataire s’engage à réexpédier, en
                          France Métropolitaine, le courrier du Domicilié, à l’exclusion de tout
                          colis. Pour toute réexpédition à l’international, des frais
                          supplémentaires pourront être réclamés. Le courrier est réexpédié de façon
                          hebdomadaire ou mensuelle par le Domiciliataire à l’adresse communiquée
                          par le Domicilié. Le Domicilié est seul responsable de l’adresse de
                          réexpédition communiquée au Domiciliataire. En cas de communication d’une
                          adresse erronée ou de retour de courrier, les Parties conviendront d’un
                          commun accord des conditions financières de la réexpédition dudit
                          courrier.
                        </p>
                        <p>
                          Cette prestation est limitée à un maximum de 90 courriers par mois
                          réexpédiés. Au-delà, chaque courrier réexpédié sera facturé à hauteur d'un
                          (1) euro hors taxes par le Domiciliataire.
                        </p>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </section>

            {/****** Section Article 2, 2.2 Obligations du Domicilié ******/}
            <section className={classes.textPadding}>
              <p className={classes.articleTitle}>2.2. Obligations du Domicilié</p>
              <p>
                Dès la signature du Contrat et durant toute la durée de celui-ci, le Domicilié
                s’engage à :
              </p>
              <div>
                <ul className={classes.ulsStyle}>
                  <li>
                    <p>
                      lorsque le Domicilié est une personne morale, justifier de son inscription au
                      Registre du Commerce ou des Métiers ou toutes autres administrations
                      compétentes dans les trois mois qui suivent la date d’engagement de la
                      domiciliation, faute de quoi le Domiciliataire se réserve le droit de
                      rechercher toutes les informations légales contenues dans ces documents aux
                      frais du Domicilié.*
                    </p>
                    <p>
                      Lorsque le Domicilié est une personne physique, justifier d’un récépissé de
                      microentreprise dans les trois mois qui suivent la date d’engagement de la
                      domiciliation.*
                    </p>
                    <p>
                      Le Domicilié s’engage, en outre et en sus des engagements sus évoquées, à
                      communiquer au Domiciliataire un extrait KBIS de moins de 3 mois les 15
                      janvier de chaque année, le rappel de ces obligations sera accompli par le
                      Domiciliataire trente (30) jours et trois (3) jours calendaires précédant le
                      jour de ladite obligation. En cas de non-fourniture desdites informations, le
                      Domicilié s’acquittera d’un règlement de quinze (15) euros hors taxes.
                    </p>
                  </li>
                  <li>
                    <p>
                      justifier de l’identité de son représentant légal en produisant à minima deux
                      (2) documents d’identité officiels (Passeport, Carte Nationale d’Identité,
                      Permis de conduire, Titre de Séjour) et en cours de validité. Si l’un des
                      documents arrive à expiration, le Domicilié s’engage à le mettre à jour via
                      son espace en ligne ou l’adresser par courriel aux services du Domiciliataire
                      dans les trente (30) jours de la date d’expiration du document concerné.
                    </p>
                    <p>
                      A défaut, (i) les services du Domicilié seront suspendus et (ii) une pénalité
                      de cinq (5) euros hors taxes mensuelle pourra être appliquée en sus de la
                      redevance mensuelle due. Le Domiciliataire se réserve, en outre, le droit de
                      résilier le Contrat aux torts exclusifs du Domicilié.
                    </p>
                  </li>
                  <li>
                    certifier l’exactitude des renseignements fournis dans le cadre des présentes et
                    qu’il n’est pas en état de cessation des paiements ni ne fait l’objet d’une
                    procédure de prévention et de traitement des difficultés ou de toute mesure ou
                    procédure similaire ou équivalente en vertu du droit qui le régit. En tout état
                    de cause, le Domicilié fera sa propre affaire des dettes pouvant exister à son
                    départ. Le Domicilié accepte, d’ores et déjà et sans condition, que toute
                    information fournie au Domiciliataire pourra être communiquée à première demande
                    par ce dernier aux représentants des organismes officiels (service des impôts,
                    police, huissiers de justice sur autorisation judiciaire…) sans information
                    préalable du Domicilié.
                  </li>
                  <li>
                    utiliser effectivement et exclusivement les locaux, soit comme siège de
                    l’entreprise, soit si le siège est situé à l’étranger comme agence, succursale
                    ou représentation ;
                  </li>
                  <li>
                    tenir informé le Domiciliataire de toute modification concernant son activité ;
                  </li>
                  <li>
                    déclarer, sans délai et avant toute modification auprès du greffe du Tribunal de
                    commerce ou de la Chambre des Métiers compétent, tout changement statutaire, de
                    forme juridique, d’objet, de dénomination sociale, de nom commercial, de sigle,
                    de bénéficiaire effectif ainsi que toute modification ayant trait aux
                    représentants légaux du Domicilié ; Une fois ces changements réalisés, le
                    Domicilié devra adresser au Domiciliataire, dans un délai de quinze jours
                    calendaires, un KBIS, les statuts ainsi qu’une Déclaration des Bénéficiaires
                    Effectifs à jour.*
                    <br />
                    Il est expressément prévu que dans l’hypothèse d’un contrôle sur place des
                    Inspecteurs de la direction générale de la concurrence, de la consommation et de
                    la répression des fraudes (DGCCRF) ou des inspecteurs des impôts ou de tout
                    autre autorité de contrôle étatique ou européenne, incluant tout commissaire de
                    justice, destiné à vérifier, notamment, la mise en œuvre des obligations de
                    vigilance et de déclaration de soupçon du Domiciliataire, les agissements du
                    Domicilié, le Domicilié, visé par ce contrôle, s’engage à régler à chaque
                    passage de ces autorités, la somme de cent (100) euros hors taxes outre
                    l’ensemble des débours occasionnés par ces passages.
                  </li>
                  <li>
                    ne pas communiquer à tout tiers les coordonnées du Domiciliataire, qu’il
                    s’agisse de l’adresse du 10 Rue Penthièvre, ou de toute autre agence du
                    Domiciliataire, du standard téléphonique, des adresses e-mail des différents
                    services ou membres des équipes du Domiciliataire, en tant que support à quelque
                    titre que ce soit du Domicilié (support commercial, service après-vente ou
                    autre). L’adresse de domiciliation et les services associés ne doivent être
                    utilisés par le Domicilié uniquement conformément à leur destination. Le
                    Domicilié ne saurait, pour autant, mêler d’une quelque manière que ce soit le
                    Domiciliataire à son activité en tant que telle. Il est expressément prévu que,
                    le Domicilié s’engage à régler pour chaque plainte adressée par tout tiers au
                    Domiciliataire bien que concernant le Domicilié, la somme de cent euros (100)
                    hors taxes.
                  </li>
                  <li>
                    Afin d’optimiser la délivrabilité et d’assurer une distribution plus précise et
                    plus rapide des courriers, selon l’adresse de domiciliation choisie, pourra être
                    mise à disposition du domicilié une adresse CEDEX à fournir à ses organismes
                    expéditeurs.
                  </li>
                </ul>
              </div>
            </section>
          </div>
          <div>
            *Le non-respect de l'une de ces clauses entraînera une pénalité de non conformité de 70
            euros.
          </div>
          {/****** Division Article 3 : Redevance ******/}
          <div className={classes.textPadding}>
            <p className={classes.articleTitle}>Article 3 : Redevance</p>
            <p>
              En contrepartie des obligations visées à l’article 2.1, le Domicilié s’engage à régler
              une redevance mensuelle ou annuelle telle que prévue dans les Conditions
              Particulières.
            </p>
            <p>
              En cas de souscription à l’Offre Domisimple, une offre promotionnelle d’essai est
              consentie : « Scan de l'enveloppe », selon les modalités des Conditions Particulières.
              Ladite offre est consentie à titre gratuit durant les six premiers mois à compter de
              la date d'effet du Contrat. A l'issue de cette période de 6 mois, le Domicilié peut
              décider de ne pas convertir la période d’essai en inscription payante en le demandant
              via le chat en ligne sur son compte ou par email à{' '}
              <a href="mailto:support@digidom.pro">support@digidom.pro</a> à défaut de quoi cette
              option sera facturée de 10 euros par mois.
            </p>
            <p>
              Ladite redevance ainsi que l’ensemble des éventuels frais annexes sont payables
              mensuellement ou annuellement par prélèvement bancaire automatique le jour de la date
              d’effet du Contrat.
            </p>
            <p>
              La première redevance doit impérativement être payée au moment de la conclusion des
              présentes.
            </p>
            <p>
              La redevance couvre les seules prestations visées à l’article 2.1 à l’exclusion de
              toute mise à disposition des locaux devant faire alors l’objet d’une tarification
              complémentaire ou des éventuels frais de réexpédition de colis, de courriers à
              l’international ou de courrier à l’unité. Lesdits frais de réexpédition seront
              facturés en sus au Domicilié et payables par prélèvement bancaire automatique
              mensuellement en même temps que la redevance précitée.
            </p>
            <p>
              Une facture dématérialisée sera adressée mensuellement ou annuellement selon la
              fréquence choisie par le client au représentant légal du Domicilié, dans l’Espace
              Personnel.
            </p>
            <p>
              En cas d’impayé, d’anomalie de paiement ou erreur de prélèvement, le Domicilié est
              facturé immédiatement à hauteur de <b>quinze (15)</b> euros hors taxes par incident,
              mensuellement et cela, jusqu’à la régularisation complète de l’incident par le
              Domicilié. Les services fournis par le Domiciliataire, au titre des présentes,
              pourront être, en outre, immédiatement suspendus et le Contrat pourra être résilié aux
              torts exclusifs du Domicilié dans les conditions de l’article 4. Pour les règlements
              par chèques, prélèvements ou cartes bancaires remis à l’encaissement et impayés, des
              frais d’un montant de dix (10) euros hors taxes seront imputés automatiquement sur la
              prochaine facture de domiciliation En cas de règlement par chèques, virement bancaire,
              ou carte bancaire des frais d’un montant de dix (10) euros hors taxes seront imputés
              automatiquement sur la prochaine facture de domiciliation. Cette majoration sera due à
              chaque fois que le Domicilié réglera ces moyens de paiements sauf accord exprès, écrit
              et préalable du Domiciliataire. Le Domicilié donne dès à présent son accord pour une
              révision chaque année du tarif mensuel. Les prestations de services définies à
              l’Article 2.2 en fonction du taux d’inflation (indice IPC) ou de l’évolution des
              services auxquels il souscrit, ainsi que pour le mode de règlement proposé par le
              Domiciliataire.
              <br />
              Le Contrat est ferme et définitif à la signature et aucun remboursement fut-il partiel
              ne saurait être revendiqué par le domicilié.
            </p>
          </div>

          {/****** Division Article 4 : Durée du Contrat ******/}
          <div className={classes.textPadding}>
            <p className={classes.articleTitle}>Article 4 : Durée du Contrat</p>

            {/****** Section Article 4, 4.1 : Principe ******/}
            <section>
              <p className={classes.articleTitle}>4.1 : Principe</p>
              <p>
                Le Contrat est conclu pour une durée de trois (3) mois à compter de sa signature et
                renouvelable tacitement dans les conditions de l’article 4.2.
              </p>
              <p>
                Pendant toute la durée du Contrat, le Domicilié a la possibilité de changer d’Offre,
                passé le délai d'engagement de l'Offre souscrite de 3 mois. Ce changement d'Offre
                est assimilé à un réengagement d'une durée minimale de trois (3) mois, renouvelable
                ensuite dans les conditions de l’article 4.2.
              </p>
              <p>
                En cas de manquement par l’une des Parties à ses obligations contractuelles, le
                Contrat pourra être résilié de plein droit par l’autre Partie quinze (15) jours
                après l’envoi d’une lettre de mise en demeure adressée en recommandé avec avis de
                réception restée sans effet. La mise en demeure indiquera la ou les défaillances
                constatées.
              </p>
            </section>

            {/****** Section Article 4, 4.21 : Renouvellement ******/}
            <section>
              <p className={classes.articleTitle}>4.21 : Renouvellement</p>
              <p>
                Le Contrat est renouvelé, par tacite reconduction, chaque mois, sauf résiliation
                notifiée par l’une ou l’autre des parties par lettre recommandée avec accusé de
                réception et expédiée au moins 15 (quinze jours) avant le terme fixé. Avant le terme
                de ce préavis, le Domicilié devra impérativement, pour valider sa résiliation
                définitive de Contrat, adresser par courrier, une photocopie de son nouveau KBIS ou
                tout document remis par l’administration compétente, justifiant le transfert de
                siège social ou sa radiation. En l’absence de ce justificatif, le Domiciliataire se
                réserve le droit de poursuivre le Contrat dans les termes initiaux.{' '}
              </p>
              <p>
                L’adresse de correspondance est (quelle que soit l’adresse de domiciliation) :
                DIGIDOM: 10 rue de Penthièvre 75008 Paris (France).
              </p>
              <p>
                À l’expiration du Contrat ou en cas de résiliation de celui-ci, le Domiciliataire
                s’engage à informer le greffe du Tribunal de Commerce de Paris de la cessation de la
                domiciliation. Au terme du Contrat, le courrier sera refusé par le Domiciliataire
                avec l’annotation « N’habite Pas à l’Adresse Indiquée ».
              </p>
            </section>
          </div>

          {/****** Division Article 5 : Dépôt de garantie ******/}
          <div className={classes.textPadding}>
            <p className={classes.articleTitle}>Article 5 : Dépôt de garantie</p>
            <p>
              Le Domicilié verse, à la date de signature du Contrat, à titre de dépôt de garantie,
              un montant correspondant à un mois de redevance, montant fixé selon l’Offre choisie
              par le Domicilié. Ce dépôt de garantie est destiné à couvrir, notamment, (i) tout
              impayé, et/ou (ii) le paiement des sommes dues par le Domicilié dont le Domiciliataire
              pourrait être rendu, directement ou indirectement, responsable. Ledit dépôt de
              garantie ne saurait en aucun cas dispenser le Domicilié de payer toutes les redevances
              jusqu’au terme prévu. Les sommes versées à titre de dépôt de garantie ne sauraient
              produire d’intérêt au profit du Domicilié. Dans l’hypothèse où le dépôt de garantie
              versé par le Domicilié serait insuffisant pour couvrir les sommes dues, le Domicilié
              s’engage à payer, sans délai, au Domiciliataire tout complément dû.
            </p>
          </div>

          {/****** Division Article 6 : Responsabilité des Parties ******/}
          <div className={classes.textPadding}>
            <p className={classes.articleTitle}>Article 6 : Responsabilité des Parties</p>
            <p>
              Le rôle du Domiciliataire se limite à l’exécution des prestations prévues par les
              présentes.
            </p>
            <p>
              Le Domicilié garantit, pendant toute l’exécution du Contrat, que l’objet, le contenu
              et la nature de son activité ne sont pas susceptibles de porter atteinte à l’ordre
              public ou aux bonnes mœurs, de provoquer des protestations de tiers, ou encore de
              contrevenir aux dispositions légales en vigueur. Il est précisé que l’exécution des
              prestations par le Domiciliataire ne garantit nullement la légalité de l’activité du
              Domicilié dont ce dernier est le seul responsable.
              <br />
              Le Domicilié garantit, en sus, entièrement le Domiciliataire des conséquences
              économiques et/ou financières directes et/ou indirectes (y compris les frais de
              procédure et de défense) qui découleraient de toutes actions de tout tiers contre le
              Domiciliataire au titre de l’exécution par ce dernier des prestations prévues par les
              présentes au bénéfice du Domicilié
              <br />
              Il est vivement conseillé au Domicilié de s’assurer pour son activité professionnelle
              (assurance civile professionnelle) et pour les espaces qu’il occupe (assurance
              multirisque bureaux).
              <br />
              Le Domiciliataire décline toute responsabilité à l’égard du Domicilié en raison de la
              perte ou d’un dommage subi par le Domicilié en relation avec le Contrat, avec les
              prestations, le ou les espaces, à moins que la perte ou le dommage ne résulte d’un
              acte intentionnel ou d’une négligence du Domiciliataire.
              <br />
              Le Domiciliataire décline toute responsabilité en raison de la perte résultant d’un
              manquement relatif à la fourniture d’une prestation par suite d’une panne mécanique,
              d’une grève, de la déchéance des droits du Domiciliataire sur les espaces ou pour
              toute autre raison à moins que le Domiciliataire n’ait agi intentionnellement. En tout
              état de cause, le Domiciliataire ne sera responsable d’une perte ou d’un dommage que
              si le Domicilié l’en avise par écrit et lui octroie un délai qui ne peut être
              inférieur à dix (10) jours pour y remédier.
              <br />
              Le Domiciliataire ne saurait être responsable du retard ou de l’inexécution du Contrat
              justifié par un cas de force majeure, telle qu’elle est définie par la jurisprudence
              des cours et tribunaux français.
              <br />
              Le Domiciliataire ne saurait en aucune circonstance être responsable au titre des
              pertes ou dommages indirects ou imprévisibles du Domicilié ou des tiers, ce qui inclut
              notamment tout gain manqué, préjudice commercial, perte de chiffre d’affaires ou
              bénéfice, perte de clientèle ou perte de chance liée à quelque titre et sur quelque
              fondement que ce soit.
              <br />
              Si le Domicilié considère que le Domiciliataire a failli dans la fourniture des
              prestations prévues à l’article 2.1 des conditions particulières du Contrat, le
              Domicilié devra en aviser le Domiciliataire par écrit et lui octroyer un délai qui ne
              peut être inférieur à dix (10) jours afin que le Domiciliataire puisse y remédier sans
              pénalité à charge de ce dernier.
              <br />
              En tout état de cause, seuls les préjudices directs pourront permettre l’engagement de
              la responsabilité des parties, étant précisé que pour ce qui est de la responsabilité
              du Domiciliataire, cette dernière ne pourra être supérieure au total du montant des
              sommes encaissées par ce dernier et payées par le Domicilié dans le cadre de
              l’exécution du Contrat.
            </p>
          </div>

          {/****** Division Article 7 : Élection de domicile ******/}
          <div className={classes.textPadding}>
            <p className={classes.articleTitle}>Article 7 : Élection de domicile</p>
            <p>Les parties font élection de domicile à leur siège social respectif.</p>
          </div>

          {/****** Division Article 8 : Différend – Attribution de juridiction ******/}
          <div className={classes.textPadding}>
            <p className={classes.articleTitle}>
              Article 8 : Différend – Attribution de juridiction
            </p>
            <p>
              Tout litige pouvant survenir entre les parties à l’occasion de l’exécution du Contrat
              devra être porté devant le Tribunal de Commerce de PARIS.
            </p>
          </div>

          {/****** Division Article 9 : Confidentialité ******/}
          <div className={classes.textPadding}>
            <p className={classes.articleTitle}>Article 9 : Confidentialité</p>
            <p>
              Les parties s’engagent à traiter comme confidentielles toutes informations qu’elles
              seraient amenées à obtenir dans le cadre de ce Contrat.
            </p>
          </div>

          {/****** Division Article 10 : Rétractation  ******/}
          <div className={classes.textPadding}>
            <p className={classes.articleTitle}>Article 10 : Rétractation </p>
            <p>
              Le droit de rétractation s'applique uniquement aux personnes physiques agissant à des
              fins non professionnelles, celui-ci ne peut donc être invoqué dans le cadre de cette
              contractualisation, entre professionnels.
              <br />
              <br />
              La signature du contrat et le dépôt de documents à nos services impliquant une
              mobilisation des équipes, le renoncement à nos services durant la phase de préparation
              de dossier impliquera l'absence de remboursement en cas de paiement mensuel, ou un
              remboursement limité en cas de paiement ANNUEL, Digidom se réservant le droit d'en
              prélever 45 € de frais de dossiers.
            </p>
          </div>

          {/****** Division Article 11 : Dispositions diverses  ******/}
          <div className={classes.textPadding}>
            <p className={classes.articleTitle}>Article 11 : Dispositions diverses </p>
            <p>
              Le Contrat est soumis à la loi française.
              <br />
              Le fait qu’une des parties n’ait pas exigé l’application d’une clause ou stipulation
              quelconque du Contrat, que ce soit de façon permanente ou temporaire, ne pourra être
              considéré comme une renonciation aux droits de cette Partie découlant de ladite
              clause. Si tout ou partie d’une stipulation du Contrat est jugée illégale, invalide ou
              inapplicable, la stipulation s’appliquera avec les modifications minimales nécessaires
              pour la rendre légale, valide et exécutoire. Les parties doivent faire tout ce qui est
              en leur pouvoir pour parvenir à un accord sur une nouvelle stipulation légale, valide
              et exécutoire, similaire en substance afin de remplacer la stipulation illégale,
              invalide ou inapplicable.
            </p>
          </div>

          {/****** Division Annexe A - Services  ******/}
          <div className={classes.textPadding}>
            <p className={clsx(classes.articleTitle, classes.textCenter)}>Annexe A - Services</p>
            <p>
              Digidom propose la mise à disposition d’une adresse physique, pouvant être utilisée
              comme siège social ou simple adresse postale. Les services associés à cette adresse
              varieront en fonction de l’offre choisie, dont les spécificités sont détaillées dans
              les Conditions Particulières :
            </p>
            <p>
              <ul className={classes.ulsStyle}>
                <li>réception / tri / notification du courrier</li>
                <li>mise à disposition des courriers en agence</li>
                <li>procuration postale</li>
                <li>scan de l’enveloppe, offert les six premiers mois</li>
                <li>stockage des courriers pendant trois mois</li>
                <li>support illimité (chat, e-mail et téléphone)</li>
                <li>réexpédition du courrier, frais de timbres inclus</li>
                <li>numérisation du courrier</li>
              </ul>
            </p>
            <p>Se référer aux Conditions Particulières pour le prix et l’offre souscrite.</p>
          </div>
        </div>

        {/****** Division Annexe B - Conditions d’éligibilité  ******/}
        <div className={classes.textPadding}>
          <p className={clsx(classes.articleTitle, classes.textCenter)}>
            Annexe B - Conditions d’éligibilité
          </p>

          {/****** Division Annexe B - (i)  ******/}
          <div>
            <p>
              (i) être une entreprise dûment enregistrée au registre du commerce et des sociétés ou
              au registre des métiers.
            </p>

            <p>
              Il est précisé que les établissements secondaires ne peuvent être éligibles à une
              domiciliation commerciale.
            </p>
            <p>
              Concernant les personnes soumises au régime des bénéfices non commerciaux, il est
              précisé que les sociétés en nom propre ne faisant pas l’objet d’une immatriculation au
              registre des commerces et des sociétés ou d’une chambre ou du répertoire des métiers
              ne font pas nécessairement l’objet d’une domiciliation.
            </p>
            <p>
              Il en va de même pour les professions libérales non réglementées qui ne sont pas
              immatriculées au greffe du Tribunal de commerce.
            </p>
            <p>
              La domiciliation de siège social est, en outre, refusée pour les associations. Dans ce
              dernier cas, l’article 1 al. 2 des présentes aurait vocation à s’appliquer.
            </p>
          </div>

          {/****** Division Annexe B - (ii)  ******/}
          <div>
            <p>
              (ii) pour les personnes physiques et/ou représentant légaux, ne pas être détenteurs
              d’un titre de séjour expirant dans les trois mois.
            </p>

            <p>
              Cette condition s’applique pour un étranger européen ou non européen détenteur d’un
              titre de séjour en cours d’expiration, dans le prochain trimestre, celui-ci se verra
              refuser la domiciliation commerciale.
            </p>
            <p>
              Il est, en outre, précisé, que les titres de séjour délivrés par les autorités
              adéquates ne permettant pas la création d’entreprise ou l’exercice d’une activité ne
              sont pas acceptés. Il est impératif que lesdits titres de séjour mentionnent la
              création d’entreprise ou l’exercice d’une activité.
            </p>
          </div>

          {/****** Division Annexe B - (iii)  ******/}
          <div>
            <p>
              (iii) le ou les représentants légaux et/ou bénéficiaires effectifs de la structure à
              domicilier ne doivent pas être résidents ou ressortissants d’un pays désigné dans
              l’une des listes établies par le Groupe d’Action Financière (GAFI).
            </p>

            <p>
              Il est rappelé que le GAFI identifie les juridictions dont les mesures de lutte contre
              le blanchiment de capitaux et le financement du terrorisme (LBC/FT) sont faibles. Deux
              listes existent (noire et grise), et toute demande de domiciliation pour une structure
              dont l’un des bénéficiaires effectifs et/ou représentants légaux est ressortissant ou
              résident de l’une de ces deux listes ne pourra être acceptée.
            </p>
          </div>

          {/****** Division Annexe B - (iv)  ******/}
          <div>
            <p>
              (iv) le ou les représentants légaux et/ou bénéficiaires effectifs de la structure à
              domicilier ne doivent pas être des personnes politiquement exposées ou faisant l’objet
              d’un gel des avoirs.
            </p>
          </div>

          {/****** Division Annexe B - (v)  ******/}
          <div>
            <p>
              (v) En outre, tout document d'identité, justificatif d'identité, et ou de société,
              transmis à nos services et dont l'alphabet est cyrillique, chinois, grec, arabe et ou
              hébreux, devra faire l'objet d'une traduction officielle par un professionnel
              assermenté.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CGVContent;
